import React from "react";
import { Link } from "gatsby";

const breadCrumbs = props => {
  return (
    <div>
      <Link to={props.lang === "en" ? "/" : `/${props.lang}`} style={{ color: "rgb(10, 121, 202)", fontSize: "15px" }}>
        BlueMail {require('../../languages/layouts')[props.lang].title}
      </Link>
      {" > "}
      <Link
        to={props.categoryMeta}
        style={{ color: "rgb(10, 121, 202)", fontSize: "15px" }}
      >
        {props.category.replace("Amp", "&")}
      </Link>
    </div>
  );
};

export default breadCrumbs;
