import React, { Component } from "react";
import Layout from "../components/layout";
import Header from "../components/header";
import FaqList from "../components/faq-list";
import SiteConfig from "../../config/SiteConfig.json";
import CategoryTabs from "../components/BM-categotyTabs";
import { Grid } from "@material-ui/core";
import BreadCrumbs from "../components/breadCrumbs";
import { Row, Col, Container } from "reactstrap";

export default class deviceTips extends Component {
  constructor(props) {
    super(props);
    this.state = {
      devicetutorialtips: "",
      currContent: "",
      currIndex: 0,
      json: ""
    };
  }

  componentDidMount() {
    let { obj, json } = this.props.pageContext;
    let { path } = this.props;
    let arr;
    if (path.includes("tutorial")) {
      arr = obj.tutorial;
    } else if (path.includes("device-tips")) {
      arr = obj.deviceTips;
    }

    let result = arr.map((data, i) => {
      if (SiteConfig.brand === "Blix") {
        return {
          title: data.title.replace(/@brand|-------/g, SiteConfig.brand),
          content: data.content
            .replace(/@brand|-------/g, SiteConfig.brand)
            .replace(/\n/g, "<br/>")
            .replace(/<bluemail.*>.*?<\/bluemail>/gi, "")
            .replace(/<typeapp.*>.*?<\/typeapp>/gi, "")
        };
      } else if (SiteConfig.brand === "Bluemail") {
        return {
          title: data.title.replace(/@brand|-------/g, SiteConfig.brand),
          content: data.content
            .replace(/@brand|-------/g, SiteConfig.brand)
            .replace(/\n/g, "<br/>")
            .replace(/<blix.*>.*?<\/blix>/gi, "")
            .replace(/<typeapp.*>.*?<\/typeapp>/gi, "")
        };
      } else {
        return {
          title: data.title.replace(/@brand|-------/g, SiteConfig.brand),
          content: data.content
            .replace(/@brand|-------/g, SiteConfig.brand)
            .replace(/\n/g, "<br/>")
            .replace(/<bluemail.*>.*?<\/bluemail>/gi, "")
            .replace(/<blix.*>.*?<\/blix>/gi, "")
        };
      }
    });
    this.setState({
      devicetutorialtips: result,
      currContent: result[0].content,
      json: json
    });
  }

  toggle = index => {
    let { devicetutorialtips } = this.state;
    this.setState({
      currContent: devicetutorialtips[index].content,
      currIndex: index
    });
  };

  render() {
    let { devicetutorialtips, currContent, currIndex, json } = this.state;
    let { category, lang, categoryMeta, title } = this.props.pageContext;
    return (
      <Layout>
        <Header
          searchHide={false}
          lang={lang}
          json={json}
          activeTab={category}
        />

        {json && (
          <CategoryTabs
            json={json}
            lang={lang}
            category={category}
            showPosts={false}
          />
        )}

        <Container
          id="tip-post"
          className="tip-container-bm"
          style={{ paddingTop: "0" }}
        >
          <div className="tip-post-padding-bm">
            <BreadCrumbs
              categoryMeta={
                lang === "en" ? categoryMeta : `/${lang}${categoryMeta}`
              }
              category={category}
              lang={lang}
            />
            <h2 style={{ padding: "20px 0 15px 0", marginBottom: "0" }}>
              {title}
            </h2>

            <Grid container spacing={16} className="tutorial_m">
              {devicetutorialtips &&
                devicetutorialtips.map((data, i) => {
                  return (
                    <Grid item md={12} xs={12} key={i}>
                      <FaqList title={data.title} content={data.content} />
                    </Grid>
                  );
                })}
            </Grid>
            <Row className="tutorial_d">
              <Col md="5">
                {devicetutorialtips &&
                  devicetutorialtips.map((data, i) => {
                    return (
                      <div
                        key={i}
                        className={
                          i === currIndex
                            ? "tutorial_d_button active"
                            : "tutorial_d_button"
                        }
                        onClick={() => this.toggle(i)}
                      >
                        {data.title}
                      </div>
                    );
                  })}
              </Col>

              <Col md="7">
                <div dangerouslySetInnerHTML={{ __html: currContent }}></div>
              </Col>
            </Row>
          </div>
        </Container>
      </Layout>
    );
  }
}
