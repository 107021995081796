import React from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

const FaqList = (props) => {
    return (
        <Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        {props.title}
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p style={{fontSize: '15px', lineHeight: '2.5'}} dangerouslySetInnerHTML={{ __html: props.content }}></p>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
    )
}

export default FaqList;